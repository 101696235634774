import $ from 'jquery'

$(document).on('click', '.detail-action', function (e) {
  e.preventDefault()

  const $target = $(e.currentTarget)
  const id = $target.data('invoice-id')
  const $modal = $(`#videoPurchaseModal${id}`)

  console.log($modal)
  if ($modal.length > 0) {
    $modal.modal('show')
  } else {
    const targetUrl = $target.data('modal-url')
    getAndAppendModal(targetUrl)
  }
})

function getAndAppendModal (targetUrl) {
  $.ajax({
    url: targetUrl,
    method: 'GET'
  }).done((data) => {
    $('#modal-container').append(data)
    $(data).modal('show')
  })
}
