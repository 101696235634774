import videojs from 'video.js'
import $ from 'jquery'

const disableUnseenScrubbing = function (player) {
  return {
    setSource: function setSource (srcObj, next) {
      next(null, srcObj)
    },
    setCurrentTime: function setCurrentTime (ct) {
      const highestTime = $('#playback-data').data('highest-time')

      if (ct <= highestTime) {
        return ct
      } else {
        return player.currentTime()
      }
    }
  }
}

const playerIsActive = (player) => player.currentTime() > 0 && !player.ended() && !player.paused()

const trackProgress = (player, force) => {
  if (force || playerIsActive(player)) {
    const $dataEl = $('#playback-data')
    const trackUrl = $dataEl.data('track-url')

    $.ajax({
      url: trackUrl,
      method: 'PUT',
      contentType: 'application/json; charset=UTF-8',
      data: JSON.stringify({
        current_time: player.currentTime()
      })
    }).done((data) => {
      $('span.video-highest-time').text(data.highest_time + ' Sek.')
      $dataEl.data('highest-time', data.highest_time)
      $dataEl.data('current-time', data.current_time)
      if (player.currentTime() > (player.duration() * 0.9)) {
        $('#questions').show()
      }
    })
  }
}

let player = null
let progressTimer = null

const initializePlayer = function () {
  if ($('#webinar').length > 0) {
    $('#webinar').hide()
    $('#webinar').clone().attr('id', 'webinar-videojs').show().appendTo('#playback-data')

    player = videojs('webinar-videojs', {
      controls: true,
      autoplay: false,
      preload: 'auto'
    })

    player.ready(function () {
      const currentTime = $('#playback-data').data('current-time')
      this.currentTime(currentTime)
    })

    player.on('play', function () {
      trackProgress(this, true)
    })

    player.on('ended', function () {
      trackProgress(this, true)
    })

    progressTimer = setInterval(function () {
      trackProgress(player, false)
    }, 10000)
  } else {
    player = null
    progressTimer = null
  }
}

const cleanupPlayer = function () {
  if (player !== null) {
    player.dispose()
  }

  if (progressTimer !== null) {
    clearInterval(progressTimer)
    progressTimer = null
  }
}

videojs.use('*', disableUnseenScrubbing)

$(document).on('turbolinks:before-cache', cleanupPlayer)
$(document).on('turbolinks:load', initializePlayer)

$(document).on('click', '#playback', function () {
  if (player !== null) {
    if (playerIsActive(player)) {
      player.pause()
    } else {
      player.play()
    }
  }
})

$(window).on('blur', () => {
  if (player !== null && playerIsActive(player)) {
    player.pause()
  }
})
