/* global Stripe */

import '@stripe/stripe-js'
import $ from 'jquery'

$(document).on('click', '#checkout-button[data-stripe-key]', async function () {
  const stripeKey = $(this).data('stripe-key')
  const sessionId = $(this).data('session-id')

  if (typeof sessionId === 'string' && sessionId.length > 0) {
    const stripe = Stripe(stripeKey)

    stripe.redirectToCheckout({
      sessionId: sessionId
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.

      console.error(result.error.message)
    })
  }
})
