import $ from 'jquery'
$(document).on('turbolinks:load', function () {
  $('.impersonate-action-icon').on('mouseenter', function () {
    $('.unhovered').hide()
    $('.hovered').show()
  })
  $('.impersonate-action-icon').on('mouseleave', function () {
    $('.hovered').hide()
    $('.unhovered').show()
  })
})
