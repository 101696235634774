import $ from 'jquery'

$(document).on('click', '.question-answer', function () {
  const $el = $(this)
  const questionId = $el.attr('data-question-id')

  // Clear old elements
  $(`*[data-question-id="${questionId}"]`).removeClass('checked')
  $(`*[data-question-id="${questionId}"]`).attr('data-checked', '0')

  // Update new element
  $el.attr('data-checked', '1')
  $el.addClass('checked')
  $el.find('input[type="radio"]').prop('checked', true)
})
