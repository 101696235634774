import $ from 'jquery'
import bsCustomFileInput from 'bs-custom-file-input'

require('nedeco/datatables')
require('nedeco/video_player')
require('nedeco/awards')
require('nedeco/stripe')
require('nedeco/questions')
require('nedeco/round-check_box')
require('nedeco/invoices')
require('nedeco/home')
require('nedeco/video')
require('nedeco/navigation')
require('nedeco/events')
require('nedeco/webinars')

function getRandomInt (max) {
  return Math.floor(Math.random() * Math.floor(max))
}

$(document).on('click', '.duplicate-nested-form', function () {
  // e.preventDefault()
  const containerSelector = $(this).data('container')
  const $container = $(containerSelector).last()
  const randomId = getRandomInt(99999)

  const children = $container.children().first().clone()
  const childElements = children.children()

  // Remove destroy from new objects
  childElements.find('.destroy').parent().remove()

  // Remove value of input field
  // Remove value from checkbox
  const newElement = children.html(children.html()
    .replace(/\]\[\d+\]\[/g, `][${randomId}][`)
    .replace(/attributes_\d+_/g, `attributes_${randomId}_`)
  )

  $container.append(newElement)

  return false
})

// Generic direct upload handler
$(document).on('turbolinks:before-cache', () => bsCustomFileInput.destroy())
$(document).on('turbolinks:load', () => bsCustomFileInput.init())

$(document).on('direct-upload:initialize', function (event) {
  const progressSelector = $(event.target).data('progress-bar')
  const $progressWrapper = $(progressSelector)

  $progressWrapper.removeAttr('hidden')
})

$(document).on('direct-upload:start', function (event) {
  const progressSelector = $(event.target).data('progress-bar')
  const $progress = $(`${progressSelector} > .progress-bar`)
  const value = '0%'

  $progress.width(value).text(value)
})

$(document).on('direct-upload:progress', function (event) {
  const progressSelector = $(event.target).data('progress-bar')
  const $progress = $(`${progressSelector} > .progress-bar`)
  const value = `${Math.round(event.detail.progress)}%`

  $progress.width(value).text(value)
})

$(document).on('direct-upload:error', function (event) {
  const progressSelector = $(event.target).data('progress-bar')
  const $progress = $(`${progressSelector} > .progress-bar`)
  const value = '100%'

  $progress.width(value).text(value).removeClass('progress-bar-animated').addClass('bg-danger')
})

$(document).on('direct-upload:end', function (event) {
  const progressSelector = $(event.target).data('progress-bar')
  const $progress = $(`${progressSelector} > .progress-bar`)
  const value = '100%'

  $progress.width(value).text(value).removeClass('progress-bar-animated').addClass('bg-success')
})

// Ajax error handler
$(document).on('ajax:error', 'form[data-remote]', function (event) {
  const $form = $(this)
  const model = $form.attr('id').replace(/[a-z]+_/i, '')
  const [data, status, xhr] = event.detail

  // remove old errors
  $form.find('.invalid-feedback').remove()

  // add new errors
  if (status === 'Unprocessable Entity' && xhr.getResponseHeader('Content-Type').startsWith('application/json')) {
    $.each(data.errors, (key, value) => {
      const $el = $form.find(`#${model}_${key}`)
      const error = `<div class="invalid-feedback">${value.join(', ')}</div>`

      $el.addClass('is-invalid')
      $el.parent().append(error)
    })
  }
})

$(function () {
  window.setTimeout(function () {
    $('.alert').fadeTo(500, 0).slideUp(500, function () {
      $(this).remove()
    })
  }, 4000)
})
