import $ from 'jquery'

$(document).on('click', '.load_more_entries', function (e) {
  e.preventDefault() // don´t scroll up
  const videosContainer = $(this).parent().parent().find('.video_container')
  const targetUrl = $(this).data('target-url')
  const currentLimit = $(this).data('current-limit')
  const newLimit = currentLimit + 6
  const $that = $(this)

  $.ajax({
    type: 'GET',
    url: targetUrl,
    data: { limit: newLimit },
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
    }
  })
    .then(function (response) {
      const partial = response
      $that.data('current-limit', newLimit)
      videosContainer.html(partial)

      if ($that.data('max-entries') < newLimit) {
        $that.remove()
      }
    })
    .catch(function (error) { console.log(error) })
})
