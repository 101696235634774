import 'datatables.net-bs4'
import $ from 'jquery'

const removeExistingDataTables = function () {
  $('.datatable').each(function () {
    const table = $(this).DataTable()

    if (table !== null) {
      table.destroy()
    }
  })
}

$(document).on('turbolinks:before-cache', removeExistingDataTables)

$(document).on('turbolinks:load', function () {
  const dataTableOptions = {
    language: {
      sEmptyTable: 'Keine Daten in der Tabelle vorhanden',
      sInfo: '_START_ bis _END_ von _TOTAL_ Einträgen',
      sInfoEmpty: 'Keine Daten vorhanden',
      sInfoFiltered: '(gefiltert von _MAX_ Einträgen)',
      sInfoPostFix: '',
      sInfoThousands: '.',
      sLengthMenu: '_MENU_ Einträge anzeigen',
      sLoadingRecords: 'Wird geladen ..',
      sProcessing: 'Bitte warten ..',
      sSearch: 'Suchen',
      sZeroRecords: 'Keine Einträge vorhanden',
      oPaginate: {
        sFirst: 'Erste',
        sPrevious: 'Zurück',
        sNext: 'Nächste',
        sLast: 'Letzte'
      },
      oAria: {
        sSortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
        sSortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
      },
      select: {
        rows: {
          _: '%d Zeilen ausgewählt',
          0: '',
          1: '1 Zeile ausgewählt'
        }
      },
      buttons: {
        print: 'Drucken',
        colvis: 'Spalten',
        copy: 'Kopieren',
        copyTitle: 'In Zwischenablage kopieren',
        copyKeys: 'Taste <i>ctrl</i> oder <i>⌘</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.',
        copySuccess: {
          _: '%d Zeilen kopiert',
          1: '1 Zeile kopiert'
        },
        pageLength: {
          _: 'Zeige %d Zeilen',
          '-1': 'Zeige alle Zeilen'
        }
      }
    },
    order: [[0, 'asc']],
    lengthMenu: [25, 50, 100],
    pageLength: 25
  }

  const $dataTable = $('.datatable')

  if ($dataTable.data('server-side-loading')) {
    dataTableOptions.processing = true
    dataTableOptions.serverSide = true
    dataTableOptions.deferRender = true
    dataTableOptions.ajax = $dataTable.data('origin')
  }

  if ($dataTable.data('page-length')) {
    dataTableOptions.pageLength = $dataTable.data('page-length')
  }
  // sortable = false
  const columnDefs = []

  $('table.datatable > thead > tr > th').each(function (index) {
    if ($(this).data('sortable') === false) {
      columnDefs.fill({ targets: index, orderable: false })
    }
  })
  dataTableOptions.columnDefs = columnDefs

  $dataTable.dataTable(dataTableOptions)
})
